@font-face {
    font-family: 'ComboRegular';
    src: url('./assets/fonts/Combo/fonts/ComboRegular.eot');
    src: url('./assets/fonts/Combo/fonts/ComboRegular.eot') format('embedded-opentype'), url('./assets/fonts/Combo/fonts/ComboRegular.woff2') format('woff2'), url('./assets/fonts/Combo/fonts/ComboRegular.woff') format('woff'), url('./assets/fonts/Combo/fonts/ComboRegular.ttf') format('truetype'), url('./assets/fonts/Combo/fonts/ComboRegular.svg#ComboRegular') format('svg');
}

@font-face {
    font-family: 'HandleeRegular';
    src: url('./assets/fonts/Handlee/fonts/HandleeRegular.eot');
    src: url('./assets/fonts/Handlee/fonts/HandleeRegular.eot') format('embedded-opentype'), url('./assets/fonts/Handlee/fonts/HandleeRegular.woff2') format('woff2'), url('./assets/fonts/Handlee/fonts/HandleeRegular.woff') format('woff'), url('./assets/fonts/Handlee/fonts/HandleeRegular.ttf') format('truetype'), url('./assets/fonts/Handlee/fonts/HandleeRegular.svg#HandleeRegular') format('svg');
}

@font-face {
    font-family: 'FredokaOneRegular';
    src: url('./assets/fonts/Fredoka_One/fonts/FredokaOneRegular.eot');
    src: url('./assets/fonts/Fredoka_One/fonts/FredokaOneRegular.eot') format('embedded-opentype'), url('./assets/fonts/Fredoka_One/fonts/FredokaOneRegular.woff2') format('woff2'), url('./assets/fonts/Fredoka_One/fonts/FredokaOneRegular.woff') format('woff'), url('./assets/fonts/Fredoka_One/fonts/FredokaOneRegular.ttf') format('truetype'), url('./assets/fonts/Fredoka_One/fonts/FredokaOneRegular.svg#FredokaOneRegular') format('svg');
}

:root {
    --my-blue: rgb(59, 168, 227);
    --hover-blue: rgb(4, 157, 240);
    --dark: black;
    --light: rgb(255, 255, 243);
    --clipWidth: 50vmin;
    --clipHeight: 25vmin;
    --vh: 1vh;
}

body {
    margin: 0;
    font-family: 'ComboRegular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

button {
    cursor: pointer;
    font-family: 'ComboRegular';
}